@import 'src/styles/mixins';

.header {
  @include font-size-xxl;

  color: $textColor;
  margin-bottom: $gapL;
}

.row {
  &:not(:last-child) {
    margin-bottom: $gapS;
  }
}

.rowForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: $gapM 0;
}

.rowFormField {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
}

.codeInput {
  max-width: 15rem;
}

.codeInput, .valueInput {
  label {
    span {
      display: none;
    }
  }
}

.valueInput {
  width: 40rem;
}

.switch {
  padding: 5px !important;
}

.switchBase {
  background-color: white;

  &.Mui-checked:hover {
    background-color: white !important;
  }
}

.switchTrack {
  background-color: $switchBgColor;
  opacity: 1 !important;
}

.switchChecked {
  background-color: $primaryColor;

  &.MuiSwitch-thumb {
    background-color: white !important;
  }

  .switchThumb {
    background-color: white !important;
  }

  .switchTrack {
    background-color: $primaryColor !important;
  }
}

.switchThumb {
  @include size(15rem);
  border-radius: 25% !important;
  box-shadow: none !important;
}

.button {
  height: 100%;
}

.deleteButton {
  border-color: $errorColor !important;
  color: $errorColor !important;
}

.backButton {
  text-decoration: none;
}

.saveButton {
  height: 4rem;
  width: 9rem;
}

.divider {
  margin-bottom: $gapS !important;
}