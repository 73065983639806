@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
}

.headerTitle {
  @include font-size-xxl;

  color: $textColor;
  font-weight: 600;
}

.header {
  font-size: 16px;
  font-weight: 600;
}

.mainHeaders {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $gapXXL;
}

.button {
  height: 50px;
  padding: 22px 20px;
}

.submit {
  width: 120px;
}

.form {
  width: 50vw;
}

.formRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $gapXL;
  position: relative;
}

.titleInput {
  width: 400px;
}

.messageInput {
  width: 100%;
  div {
    width: 50vw;
  }
}

.divider {
  margin-bottom: $gapXL !important;
}

.select {
  width: 45%;
  height: 48px;
  padding: 0px, 16px, 0px, 16px;
}

.datePicker {
  width: 48%;
}

.characterCount {
  font-family: Nunito;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  position: absolute;
  right:1%;
  bottom: 4%;
}

.dateSelect {
  label[data-shrink="true"] {
    top: 14px !important;
  }
  input {
  margin-top: 4px !important;
}
}