@import 'src/styles/mixins';

.form {
  display: flex;
  flex-direction: column;
  margin-top: $gapL;
}

.input {
  width: 100%;
  margin-bottom: $gapL;
}

.inputPassword {
  width: 100%;
  margin-bottom: $gapS;
}

.error {
  @include font-size-s;

  color: $errorColor;
}

.signInRow {
  margin-top: $gapL;
  max-width: $inputMaxWidth;
}

.button {
  width: 100%;
  height: 5rem;
}

.terms {
  margin-top: $gapS;
}

.forgotPassword {
  @include font-size-s;
  cursor: pointer;
  color: $primaryColor;
  font-weight: 600;
  margin-bottom: $gapXL;
}
