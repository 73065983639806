@import "src/styles/mixins";

.detailContainer {
    display: flex;
    gap: $gapM;
    margin: $gapS;
}

.detailSectionHeader {
    @include font-size-l;
    margin: $gapS;
    margin-bottom: $gapXL;
}

.detailLabel {
    width: 17rem;
    color: $textColorLight;
    display: flex;
    align-items: center;
}

.svgSpan {
    margin-left: $gapS;
}

.detailValue {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    overflow-wrap: anywhere;
    max-width: 50%;
}
a .details {
    display: grid;
    gap: $gapXL;

    &_transaction {
        grid-template-columns: 1fr 1fr;
    }
}

.status {
    @include font-size-xs;

    width: fit-content;
    padding: 1px $gapS;
    border-radius: $borderRadius;
    margin-right: $gapS;

    &.success {
        color: $successColor;
        background-color: $successBgColor;
    }

    &.failure {
        color: $errorColor;
        background-color: $failedBgColor;
    }

    &.unconfirmed {
        color: $warningColor;
        background-color: $warningBgColor;
    }
}
