@import 'src/styles/mixins';

.searchBarContainer {
  display: flex;
}

.searchInput {
  flex: 1 1 auto;
  margin-right: $gapL;
}

.searchButton {
  flex: 1 1 auto;
  height: 100%;
  width: 4rem;
}