@import 'src/styles/mixins';

.appLogo {
  display: flex;
  align-items: center;
}

.rcbcLogo {
  height: 100%;
  max-width: 100%;
}

.appLogoSeparator {
  width: 2px;
  height: 30%;
  background: $primaryColor;
  margin-left: $gapXS;
  margin-right: $gapS;
  border-radius: $borderRadius;
}

.appName {
  @include font-size-m;

  color: $primaryColor;
}
