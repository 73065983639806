@import '../mixins';

/* stylelint-disable selector-class-pattern */

.MuiDataGrid {
  &-cell {
    @include font-size-s;

    border-bottom: 1px solid $lineColor;
    letter-spacing: 0;
    line-height: 1.8rem;
  }

  &-columnsContainer {
    background: $backgroundColor;
  }

  &-columnHeaderTitle {
    @include font-size-s;
  }

  &-iconSeparator {
    display: none;
  }

  &-columnSeparator {
    display: none !important;
  }

  &-row {
    color: $textColor;
  }

  &-root {
    border: 0 !important;

    .MuiDataGrid-columnHeader {
      color: $textColorLight;
      flex: 1;
      width: auto;

      &:focus {
        outline: none !important;
      }
    }

    .MuiDataGrid-columnHeaderTitleContainer {
      padding: 0;
    }

    .MuiDataGrid-row {
      cursor: pointer;
      width: 100%;
    }

    .MuiDataGrid-cell {
      flex: 1;
      width: auto;

      &:focus, &:focus-within {
        outline: none !important;
      }

      &:last-of-type {
        border-right: 0 !important;
      }
    }

    .MuiDataGrid-menuIcon {
      display: none;
    }
  }
}

.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell {
  border-bottom-color: $lineColor !important;
}

.Mui-odd {
  background-color: $backgroundColor;
}

.MuiDataGrid-overlay {
  z-index: 10;
}

//#EEF4FA

.MuiInputBase-root {
  background-color: #EEF4FA !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #EEF4FA !important;
}

// .MuiFormLabel-filled {
//   top: 12px !important;
// }