@import 'src/styles/mixins';

.contentSpinner {
  @include size(6.4rem);

  margin-bottom: $gapXL;
  margin-left: auto;
  margin-right: auto;
  margin-top: $gapXL;
  position: relative;

  .fragment {
    @keyframes circlesResizing {
      20%,
      80% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.5);
      }
    }

    @include size(0.5rem);

    animation: circlesResizing 1.2s linear infinite;
    background: $white;
    border-radius: 50%;
    position: absolute;

    &_primary {
      background: $primaryColor;
    }

    &_textColor {
      background: $textColorLight;
    }

    // coordinates representing loader circles positions
    $fragmentsLeftCoordinates: (5.3, 5, 4.1, 2.9, 1.8, 0.9, 0.6, 0.9, 1.8, 2.9, 4.1, 5);
    $fragmentsTopCoordinates: (2.9, 1.8, 0.9, 0.6, 0.9, 1.8, 2.9, 4.1, 5, 5.3, 5, 4.1);

    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        animation-delay: calc(#{$i} * (-0.1s));
        left: nth($fragmentsLeftCoordinates, $i) * (1rem);
        top: nth($fragmentsTopCoordinates, $i) * (1rem);
      }
    }
  }
}

.buttonSpinner {
  align-items: center;
  border-radius: $borderRadius;
  box-sizing: border-box;
  cursor: default;
  display: flex;
  height: calc(100% + 2px);
  left: -1px;
  pointer-events: none;
  position: absolute;
  top: -1px;
  width: calc(100% + 2px);

  .fragment {
    @keyframes ringSpinning {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }

    animation: ringSpinning 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $white transparent transparent;
    border-radius: 50%;
    border-style: solid;
    border-width: 2px;
    box-sizing: border-box;
    height: 1.6rem;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
    width: 1.6rem;

    &_primary {
      border-color: $primaryColor transparent transparent;
    }

    &_textColor {
      border-color: $textColorLight transparent transparent;
    }

    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        animation-delay: calc(#{$i} * (-0.15s));
      }
    }
  }
}
