@import 'src/styles/mixins';

.container {
  height: 5rem;
  max-width: $desktopMainMaxWidth;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1 1 auto;
  width: 100%;
}

.navButton {
  @include default-transition;

  height: 100%;
  width: 14rem;
  color: $textColorLight;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  text-decoration: none;

  &:hover, &_active {
    background: $buttonHoverColor;
    color: $primaryColor;
  }
}

.navButtonContentWrapper {
  @include default-transition;

  border-left: 3px solid white;
  display: flex;
  height: 65%;
  align-items: center;
  width: 100%;
  user-select: none;
}

.navButton:hover .navButtonContentWrapper,
.navButton_active .navButtonContentWrapper {
  border-left: 3px solid $primaryColor;
}

.navButtonIcon {
  margin-left: $gapXS;
  margin-right: $gapXS;
  display: flex;
  align-items: center;
}

.navButton:hover .navButtonIcon svg path,
.navButton:hover .navButtonIcon svg rect,
.navButton_active .navButtonIcon svg path,
.navButton_active .navButtonIcon svg rect {
  fill: $primaryColor;
  stroke: $primaryColor;
}
