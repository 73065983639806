@import "src/styles/mixins";

.container {
  background-color: $backgroundColor;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: $gapL;
}

.formContainer {
  margin-top: $gapXXL;
  background-color: white;
  padding: $gapXXL;
  border-radius: 12px;
  width: 35rem;
}

.input {
  max-width: $inputMaxWidth;
  width: 100%;
}

.inputPassword {
  margin-bottom: $gapS;
  max-width: $inputMaxWidth;
  width: 100%;
}

.error {
  @include font-size-s;

  color: $errorColor;
}

.signInRow {
  margin-top: $gapXL;
  max-width: $inputMaxWidth;
  width: 100%;
}

.button {
  width: 100%;
  height: 5rem;
  margin-top: $gapS;
}

.terms {
  margin-top: $gapS;
}

.forgotPassword {
  @include font-size-s;
  cursor: pointer;
  color: $primaryColor;
  font-weight: 600;
}

.header {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-top: $gapXL;
  margin-bottom: 0.5rem;
}

.description {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: $textColorLight;
}

.footer {
  @include font-size-s;
  margin-bottom: $gapXXL;
  background: $backgroundColor;
  color: $textColorLight;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  width: 100%;
}

.divider {
  margin-bottom: $gapM !important;
  width: 100%;
}

.code {
  width: 100%;
  margin: 1.8rem 0 1rem;

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: $textColorLight;
  }
}

.codeSend {
  cursor: pointer;
  color: $primaryColor !important;
}

.passwordTooltip {
  margin-bottom: $gapM;
  width: 100%;
}

.hiddenForm {
  height: 0px;
  background: transparent;
  display: none;
  color: transparent;
  border: none;
}
