@import 'src/styles/mixins';

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 22px;
    max-width: 300px;
}

.text {
    color: black !important;
    font-size: 14px !important;
    font-weight: 200;
}

.headerText {
    font-size: 24px !important;
    font-weight: 600;
}

.button {
  width: 25rem;
  height: 4rem;
}

.buttonSet {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10px;
}

.characterCount {
  font-family: Nunito;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  position: absolute;
  right:1%;
  bottom: 4%;
}

.proceed {
    background-color: red !important;
}