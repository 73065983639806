@import 'src/styles/mixins';

.button {
  width: 25rem;
  height: 4rem;
}

.buttonSet {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
}

.formRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $gapXL;
  position: relative;
}

.characterCount {
  font-family: Nunito;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  position: absolute;
  right:1%;
  bottom: 4%;
}

.headerDeleted {
  @include font-size-l;
  border-radius: $borderRadius;
  padding: 1rem;
  background: rgba(63, 62, 62, 0.1);
  color: #FF9A33;
  background-color: #FFF5EB;
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
}