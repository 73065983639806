@import 'src/styles/mixins';

.footer {
  width: 100%;
  padding-top: $gapL;
}

.pageSizeSelect {
  display: flex;
  align-items: center;
  gap: $gapM;
}