@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.divider {
  margin-top: $gapXL !important;
  margin-bottom: $gapXL !important;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
}

.emptyStateText {
  color: $textColorLight;
  text-align: center;

  .emptyResultText {
    @include font-size-xl;

    color: black;
    margin-bottom: $gapXS;
  }
}

.tableContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
}

.resultsText {
  @include font-size-xl;
  margin-bottom: $gapXL;

  span {
    @include font-size-l;

    color: $textColorLight;
  }
}

.table {
  flex: 1 1 auto;
}