@import 'src/styles/mixins';

.form {
  display: grid;
  grid-template-columns: repeat(2, minmax(30rem, 1fr));
}

.fieldContainer {
  @include font-size-xs;

  display: flex;
  align-items: center;
  gap: $gapM;
  padding: $gapM 0;
  border-bottom: 1px solid $lineColor;

  .input {
    flex-grow: 1;

    >div {
      max-width: unset;

      input {
        @include font-size-xs;
      }
    }
  }

  .button {
    @include font-size-xs;

    padding: 0 $gapXL;
    height: 100%;

    &:disabled {
      color: $white;
      background-color: $buttonDisabledColor;
    }
  }
}