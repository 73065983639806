@import 'src/styles/mixins';

.logoutButton {
    width: 30%;
    height: 5rem;
}

.container {
    padding: 6rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15rem;
}

.subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
        margin-bottom: $gapXXL;
    }
}

.username {
    color: $primaryColor;
}
