@import 'src/styles/mixins';

.select {
  height: $basicElementHeightMinusInputBorders;
  min-height: 0;

  &_filled {
    background: transparent !important;
    padding-top: 1.3rem;
  }
}

.icon {
  transition: 0.3s ease;
}

.menu {
  background: $inputBgColor;
  padding: 0;
}

.menuWrapper {
  border-top: 1px solid $lineColor;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 0 0.5rem 1.5rem 0 $lightShadowColor;
  margin-top: 0.2rem;
}

.item {
  @include default-transition;
}
