@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 300;
  src: url('./Nunito-Light.ttf');
  src: url('./Nunito-Light.eot?#iefix') format('embedded-opentype'), url('./Nunito-Light.woff') format('woff'), url('./Nunito-Light.ttf') format('truetype');
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  src: url('./Nunito-Regular.eot');
  src: url('./Nunito-Regular.eot?#iefix') format('embedded-opentype'), url('./Nunito-Regular.woff') format('woff'), url('./Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  src: url('./Nunito-SemiBold.eot');
  src: url('./Nunito-SemiBold.eot?#iefix') format('embedded-opentype'), url('./Nunito-SemiBold.woff') format('woff'), url('./Nunito-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  src: url('./Mulish-SemiBold.ttf');
  src: url('./Mulish-SemiBold.eot?#iefix') format('embedded-opentype'), url('./Mulish-SemiBold.woff') format('woff'), url('./Mulish-SemiBold.ttf') format('truetype');
}
