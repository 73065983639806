@import 'src/styles/mixins';

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $gapXS;
  color: $primaryColor;
  background-color: $infoBackgroundColor;
  padding: $gapS 0;
}