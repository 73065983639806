@import "src/styles/mixins";

.container {
    background-color: $backgroundColor;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.messageContainer {
    margin-top: $gapXXL;
    background-color: white;
    padding: $gapXXL;
    border-radius: 12px;
    width: 35rem;
}

.subContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.logo {
    height: 7vh;
    width: 120px;
}

.button {
    width: 100%;
    height: 5rem;
}

.mainText {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
}

.subText {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $textColorLight;
    margin-top: $gapM;
    margin-bottom: $gapXXL;
}

.footer {
    @include font-size-s;
    margin-bottom: $gapXXL;
    background: $backgroundColor;
    color: $textColorLight;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6rem;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
}