@import 'src/styles/mixins';

.header {
  @include font-size-xxl;

  color: $textColor;
}

.entriesContainer {
  margin-top: $gapL;
  flex: 1 1 auto;
}

.formHeader {
  @include font-size-s;

  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: 1fr 2fr 0.93fr;
}

.button {
  width: 16%;
  height: 4rem;
  margin: 1rem 0;
}

.backButton {
  text-decoration: none;
}