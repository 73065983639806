@import 'src/styles/mixins';

.button {
    width: 200px;
    height: 48px;
    padding: 22px 0px;
}

.divider {
    margin: 20px 0px !important;
}

.headerTitle {
  @include font-size-xxl;

  color: $textColor;
}

.mainHeaders {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $gapXXL;
}

.select {
    width: 176px;
    height: 48px;
    padding: 0px, 16px, 0px, 16px;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;

  .dataContainer {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    position: relative;

    .overlay {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;

      &.emptyResultsText {
        color: $textColorLight;
        z-index: $zIndexOverlay + 1;
        justify-content: center;
      }

      &.loaderContainer {
        z-index: $zIndexOverlay + 2;
      }
    }


  }
}

.results {
  @include font-size-xl;

  margin-top: $gapL;
  margin-bottom: $gapXL;
  gap: $gapXS;

  display: flex;
  align-items: flex-end;
}

.totalCount {
  @include font-size-m;
  color: $textColorLight;
  line-height: 2.5rem;
}

.noDataText {
  margin: 5rem;
  font-size: $gapL;
  color: $textColorLight;
  font-weight: 400;
  text-align: center;
}