/// Variables

// colors
$primaryColor: #397ec9;
$backgroundColor: #f9fcff;
$buttonHoverColor: rgba(77, 124, 254, 0.15);
$infoBackgroundColor: rgba(77, 124, 254, 0.1);
$calendarActionsBackgroundColor: #F3F7FB;

$errorColor: #f84c1e;
$successColor: #21AFC0;
$successBgColor: rgba(33, 175, 192, 0.15);
$failedBgColor: rgba(248, 76, 30, 0.1);
$initialsCircleColor: #c3e7ff;
$initialsTextColor: #4a83d3;
$warningBgColor: #feeee1;
$warningColor: #ff9a33;
$errorBgColor: #fbe4e1;
$balanceCellColor: #168b99;

$disabledColor: rgba(0, 0, 0, 0.26);
$buttonDisabledColor: #b1becf;
$inputBgColor: #eef4fa;
$switchBgColor: #e8eff7;
$checkboxColor: #dce1e7;

$textColor: #3f3e3e;
$textColorLight: #778699;
$lineColor: #d7e1ea;

$lightShadowColor: rgb(74 80 91 / 12%);

$white: #fff;

// breakpoints in px unit
$breakpointSm: 360;
$breakpointMd: 768;
$breakpointLg: 1180;
$breakpointExtraLg: 1480;

// font and base size
$mainFontFamily: 'Nunito', 'Open Sans', sans-serif;
$secondaryFontFamily: 'Mulish', 'Open Sans', sans-serif;
$baseFontSize: 10px;

$inputMaxWidth: 39.5rem;
$basicElementHeight: 2rem;
$basicElementHeightMinusInputBorders: (
  $basicElementHeight - 0.4rem
);

$borderRadius: 0.4rem;

$desktopMainMaxWidth: 106rem;
$desktopSidebarWidth: 39rem;

$fontSizes: (
  xxl: 3.2rem,
  xl: 2.4rem,
  l: 1.6rem,
  m: 1.4rem,
  s: 1.2rem,
  xs: 1rem,
  xxs: 0.8rem
);

// sizes
$baseGap: 0.5rem;
$gapXS: $baseGap;
$gapS: 2 * $baseGap;
$gapM: 3 * $baseGap;
$gapL: 4 * $baseGap;
$gapXL: 6 * $baseGap;
$gapXXL: 10 * $baseGap;

// z-indexes
$zIndexNegative: -1;
$zIndexStandard: 1;
$zIndexTop: 2;
$zIndexOverlay: 3;