@import "src/styles/mixins";

.searchBarContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.username {
    flex-basis: 34%;
    flex-grow: 1;
}

.activitySelect,
.dateFrom,
.dateTo {
    flex-basis: 16%;
    flex-grow: 1;
}

.searchButton {
    flex-basis: 10%;
    padding: 2.6rem !important;
}
