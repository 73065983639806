@import 'src/styles/mixins';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: $backgroundColor;
}

.formContainer {
  background: $white;
  display: flex;
  flex-direction: column;
  width: 30rem;
  padding: $gapL;
  border-radius: $borderRadius;
  align-items: center;
}

.form {
  margin-top: $gapXL;
  width: 100%;
}
