@import 'mixins';

html {
  font-size: $baseFontSize;
}

body {
  @include font-size-m;

  color: $textColor;
  margin: 0;
  overflow-y: scroll;
  padding: 0;

  * {
    font-family: $mainFontFamily;
    font-weight: normal;
  }

  strong {
    font-weight: bold;
  }
}

h1 {
  @include font-size-xl;

  margin-bottom: $gapM;
  margin-top: 0;
}
