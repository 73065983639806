@import 'src/styles/mixins';

.form {
  display: grid;
  grid-template-columns: repeat(5, minmax(15rem, 1fr)) minmax(10rem, auto);
  padding-bottom: $gapXL;
  column-gap: $gapL;
}

.button {
  height: 100%;
  padding: 0 $gapXL;
}