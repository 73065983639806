@import 'src/styles/mixins';

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.header {
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: center;
}

.headerContent {
  max-width: $desktopMainMaxWidth;
  height: 6rem;
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  padding-top: $gapXS;
  padding-bottom: $gapXS;
  padding-right: $gapS;
}

.loginInfoContainer {
  @include font-size-s;

  display: flex;
  align-items: center;
}

.loggedAsText {
  color: $textColorLight;
  flex: 1 1 auto;
  white-space: nowrap
}

.loggedAsUser {
  color: $textColor;
  margin-right: $gapS;
}

.logoutButton {
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 100%;
  max-width: 100%;
}

.contentWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
  background: $backgroundColor;
  width: 100%;
  padding-top: $gapS;
}

.content {
  max-width: $desktopMainMaxWidth;
  flex: 1 1 auto;
  width: 100%;
  display: flex;
}

.footer {
  @include font-size-s;

  background: $backgroundColor;
  color: $textColorLight;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 6rem;
  width: 100%;
}