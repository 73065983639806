@import 'src/styles/mixins';

.loginInfoContainer {
  @include font-size-s;

  display: flex;
  align-items: center;
}

.loggedAsText {
  color: $textColorLight;
  flex: 1 1 auto;
  white-space: nowrap
}

.loggedAsUser {
  color: $textColor;
  margin-right: $gapS;
}

.logoutButton {
  align-items: center;
  display: flex;
  justify-content: center;
  max-height: 100%;
  max-width: 100%;
  cursor: pointer;
}
