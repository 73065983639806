@import 'src/styles/mixins';
;

.icon {
  margin-left: -$gapXS;
  margin-right: $gapXS;

  &_end {
    margin-left: $gapXS;
    margin-right: -$gapXS;
  }

  g,
  path {
    @include default-transition;
  }
}

.closeIcon {
  &_white {
    path {
      fill: $white;
    }
  }
}

.button {
  @include default-transition;

  border-color: $primaryColor;
  border-width: 2px !important;
  height: $basicElementHeight;
  padding-left: $gapM;
  padding-right: $gapM;

  &_disabled {

    .undoIcon,
    .addIcon,
    .closeIcon {
      path {
        fill: $disabledColor;
      }
    }

    .saveIcon {
      path {
        stroke: $disabledColor;
      }
    }

    .arrowIcon {
      g {
        fill: $disabledColor;
      }
    }

    .backIcon {
      g {
        fill: $primaryColor;
      }
    }
  }

  &:hover {
    border-width: 2px !important;
  }
}

.label {
  @include text-overflow-ellipsis;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.hidden {
  opacity: 0;
}