@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
}

.headerTitle {
  @include font-size-xxl;

  color: $textColor;
}

.mainHeaders {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $gapXXL;
}

.button {
    // width: 150px;
    height: 40px;
    padding: 22px 0px;
}

.buttonSet {
    display: flex;
    gap: 20px;
}

.detailsSectionContent {
  display: grid;
  row-gap: $gapL;
}

.detailSet {
  display: flex;
  gap: 11.5rem;
}

.detailTitle {
  color: $textColorLight;
  display: inline-block;
  width: 17rem;
  font-weight: 600;
}

.detailMessage {
  color: $textColorLight;
  display: inline-block;
  font-weight: 600;
}

.title {
  font-size: 2rem;
  font-weight: 600;
}

.detailValue {
  overflow-wrap: anywhere;
  font-weight: 600;
  margin: 0;
}

.MuiButton-startIcon {
  display: none !important;
}

.buttonDelete {
  color: #F84C1E !important;
  border-color: #F84C1E !important;
}

.status > div {
  display: inline !important;
  padding: 1rem;
   font-weight: 600;
}