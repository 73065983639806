@import 'src/styles/mixins';

.status {
  align-items: center;
  border-radius: $borderRadius;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 6rem;
  padding: 0.4rem;

  &_neutral {
    background: $switchBgColor;
    color: $textColorLight;
  }

  &_success {
    background: $successBgColor;
    color: $successColor;
  }

  &_warning {
    background: $warningBgColor;
    color: $warningColor;
  }

  &_error {
    background: $errorBgColor;
    color: $errorColor;
  }
}
