@import 'src/styles/mixins';

.emptyTableOverlay {
  background: transparent;
  display: block;
  padding: 2rem;
  position: absolute;
  text-align: center;
  top: 2rem;
  width: 100%;
}

.positionRelative {
  position: relative;
  top: unset;
}
