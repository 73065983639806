@import 'src/styles/mixins';

.dictionaryRow {
  @include default-transition;

  display: flex;
  width: 100%;
  border-bottom: solid 1px $lineColor;
  height: 5rem;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: $buttonHoverColor;
  }

  &_header {
    color: $textColorLight;
    pointer-events: none;
  }
}

.noCell {
  width: 10rem;
  padding-left: $gapL;
}

.nameCell {
  flex: 1 1 auto;
}
