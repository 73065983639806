@import 'src/styles/mixins';

.container {
  display: grid;
  align-items: center;
}

.formHeader {
  @include font-size-xs;

  margin: $gapS 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}