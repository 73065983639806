@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;

  .dataContainer {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;
    position: relative;

    .overlay {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $white;

      &.emptyResultsText {
        color: $textColorLight;
        z-index: $zIndexOverlay + 1;
        justify-content: center;
      }

      &.loaderContainer {
        z-index: $zIndexOverlay + 2;
      }
    }

    .results {
      @include font-size-xl;

      margin-top: $gapL;
      gap: $gapXS;

      display: flex;
      align-items: flex-end;

      .totalCount {
        @include font-size-m;

        line-height: 2.5rem;
      }
    }
  }
}