@import 'src/styles/mixins';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .transactionId {
    @include font-size-m;

    align-self: flex-end;
    font-weight: 600;
  }
}

.backButton {
  @include default-transition;

  width: min-content;
  color: $primaryColor;
  cursor: pointer;
  box-sizing: content-box;
  text-decoration: none;
  margin-bottom: $gapM;

  button {
    @include font-size-s;

    padding: $gapM $gapS;
  }

  &:hover,
  &_active {
    background: $buttonHoverColor;
    color: $primaryColor;
  }
}

.detailContainer {
  display: flex;
  align-content: center;
}

.detailLabel {
  width: 17rem;
  color: $textColorLight;
  display: flex;
  align-items: center;
}

.detailValue {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
}

.textWithIcon {
  display: flex;
  align-items: center;

  svg {
    margin-right: $gapS;
  }
}

.sectionHeader {
  @include font-size-l;

  margin-bottom: $gapL;
}

.container {
  display: grid;
  gap: $gapM;

  .amount {
    @include font-size-xl;

    margin: $gapL 0;
  }

  .details {
    display: grid;
    gap: $gapXL;

    &_transaction {
      grid-template-columns: 1fr 1fr;
    }
  }

  .fromTo {
    display: flex;
    gap: $gapM;

    .detailItem {
      grid-template-columns: 12rem auto;
    }
  }

  .status {
    @include font-size-s;

    width: fit-content;
    padding: 3px $gapS;
    border-radius: $borderRadius;
    margin-right: $gapS;

    &.success {
      color: $successColor;
      background-color: $successBgColor;
    }

    &.failure {
      color: $errorColor;
      background-color: $failedBgColor;
    }

    &.unconfirmed {
      color: $warningColor;
      background-color: $warningBgColor;
    }
  }

  .edit {
    width: min-content;
    padding-left: $gapXS;
    padding-right: $gapXS;

    span {
      @include font-size-xs;

      display: flex;
      align-items: center;
      gap: $gapXS;
    }
  }
}