@import 'src/styles/mixins';

.snackbar {
  left: 0 !important;
  right: 0 !important;
  top: 7rem !important;
  transform: unset !important;

  .alert {
    @include font-size-m;
    padding: $gapS $gapXL;
    align-items: center;
    max-width: 101rem;
    width: 100%;
  }
}