@import "src/styles/mixins";

.tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
}

.countHeader {
    @include font-size-xl;
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    height: 100%;

    .dataContainer {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        height: 100%;
        position: relative;

        .overlay {
            position: absolute;
            display: flex;
            align-items: center;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $white;

            &.emptyResultsText {
                color: $textColorLight;
                z-index: $zIndexOverlay + 1;
                justify-content: center;
            }

            &.loaderContainer {
                z-index: $zIndexOverlay + 2;
            }
        }

        .results {
            @include font-size-xl;

            margin-top: $gapL;
            gap: $gapXS;

            display: flex;
            align-items: flex-end;

            .totalCount {
                @include font-size-m;

                line-height: 2.5rem;
            }
        }
    }
}

.navButton {
  @include default-transition;

  height: 100%;
  width: 10rem;
  color: $primaryColor;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: content-box;
  text-decoration: none;
  gap: $gapXS;
  padding-top: 10px;
}

.navButtonIcon svg path,
.navButtonIcon svg rect {
  stroke: $primaryColor;
}

.navButtonLabel {
  margin-bottom: 2px;
}