@import 'src/styles/mixins';

/* stylelint-disable no-descending-specificity */

.fieldWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.field {
  @include default-transition;

  max-width: $inputMaxWidth;
  width: 100%;
}

.input {
  @include font-size-s;

  border: 0;
  border-radius: $borderRadius;
  box-sizing: border-box;
  color: $textColor;
  min-height: $basicElementHeightMinusInputBorders;
  line-height: $gapL;
  padding-bottom: ($gapXS - 0.1rem);
  padding-left: ($gapM - 0.2rem);
  padding-right: ($gapM - 0.2rem);
  padding-top: ($gapXL - 0.6rem) !important;
  transition: border-color 0.2s ease, background-color 0.2s ease;

  &_textarea {
    padding-bottom: 0;
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    padding-top: 0;
    margin-top: ($gapM - 0.1rem);
    min-height: 1.8rem;
  }

  &_no_label {
    padding-top: $gapM !important;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    @include default-transition;

    -webkit-box-shadow: 0 0 0 3rem $inputBgColor inset !important;

    &:disabled {
      -webkit-box-shadow: 0 0 0 3rem $white inset !important;
    }
  }

  &[type=number] {
    -moz-appearance: textfield;
  }
}

.inputWrapper {
  @include rounded-corners;

  background: $inputBgColor !important;
  border-radius: $borderRadius;
  border: 2px solid transparent;
  min-height: $basicElementHeight;
  margin-top: 0 !important;
  transition: border-color 0.2s ease, background-color 0.2s ease;

  &::after,
  &::before {
    display: none;
  }

  &_disabled {
    background: none !important;
    border-color: $switchBgColor;
  }

  &_textarea {
    padding-bottom: 0;
    padding-top: 0;
  }

  &_focused {
    border-color: $primaryColor;
  }

  &_error {
    border-color: $errorColor;
  }

  &_end {
    padding-right: 0;
  }
}

.error {
  @include font-size-s;

  color: $errorColor;
  margin-top: 0.1rem;

  &_absolute {
    bottom: -1.8rem;
    position: absolute;
    white-space: nowrap;
  }
}

.label {
  @include font-size-s;

  color: $textColorLight !important;
  pointer-events: none;
  transform: translate($gapM, 1.3rem) scale(1);
  z-index: $zIndexTop;

  &_shrink {
    transform: translate($gapM, 0.6rem) scale(0.8) !important;
  }
}