.passwordRule {
    color: #778699;
    display: flex;
    gap: 8px;
    height: 18px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
}
.special {
    display: flex;
    color: #778699;
    gap: 7px;
    height: 18px;
    line-height: 18px;
    svg {
        width: 22px;
    }
    span {
        font-family: "Mulish";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
    }
}
