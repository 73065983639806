@import 'src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

.backButton {
  height: 4rem;

}

.deleteButton {
  height: 4rem;
  width: 180px;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: $gapL;
  flex: 1 1 auto;
}

.detailSectionHeader {
  @include font-size-l;

  margin-bottom: $gapL;
}

.businessOwnerNameContainer {
  display: flex;
  column-gap: $gapM;
  align-items: center;
  justify-content: space-between;
}

.businessOwnerName {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.businessOwnerNameButtons {
  display: flex;
  column-gap: 15px;
}

.initialsCircle {
  @include size(6rem);
  @include font-size-xxl;

  font-family: $secondaryFontFamily;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: $initialsCircleColor;
  color: $initialsTextColor;
}

.businessOwnerName {
  @include font-size-xxl;
}

.detailsSectionContent {
  display: grid;
  row-gap: $gapL;
  grid-template-columns: 25% 25% 25% 25%;

  &_personal {
    grid-template-rows: 2.5rem 2.5rem 100%;
    grid-template-areas:
      "username_name username_value phoneno_name phoneno_value"
      "middlname_name middlname_value status_name status_value"
      "cstno_name cstno_value remarks remarks_value";
  }

  &_business {
    grid-template-rows: 2.5rem;
    grid-template-areas:
      "businessName_name businessName_value . .";
  }
}

.detail {

  &_label {
    color: $textColorLight;

    &_username {
      grid-area: username_name;
    }

    &_middlname {
      grid-area: middlname_name;
    }

    &_cstno {
      grid-area: cstno_name;
    }

    &_phonno {
      grid-area: phoneno_name;
    }

    &_status {
      grid-area: status_name;
    }

    &_businessName {
      grid-area: businessName_name;
    }

    &_remarks {
      grid-area: remarks;
    }
  }

  &_value {
    &_username {
      grid-area: username_value;
    }

    &_middlname {
      grid-area: middlname_value;
    }

    &_cstno {
      grid-area: cstno_value;
    }

    &_phonno {
      grid-area: phoneno_value;
    }

    &_status {
      grid-area: status_value;
      width: 8rem;

      div {
        width: 100%;
      }
    }

    &_remarks{
      grid-area: remarks_value;
      overflow-wrap: anywhere;
    }


    &_businessName {
      grid-area: businessName_value;
    }
  }
}

.accountListBalanceCell {
  color: $balanceCellColor;
}

.deletedAccount {
  color: $buttonDisabledColor !important;
}

.headerDeleted {
  @include font-size-l;
  border-radius: $borderRadius;
  padding: 1rem;
  background: rgba(63, 62, 62, 0.1);
  color: #778699;
}
