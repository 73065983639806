@import 'src/styles/mixins';

.circle {
  @include size(2rem);

  border-radius: 50%;
  background-color: $errorColor;
  display: flex;
  justify-content: center;
  align-items: center;

  &_enabled {
    background-color: $successColor;
  }
}
