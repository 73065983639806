@import 'variables';

@mixin small-mobile-only {
  @media screen and (max-width: $breakpointSm + 'px') {
    @content;
  }
}

@mixin mobile-only {
  @media screen and (max-width: $breakpointMd + 'px') {
    @content;
  }
}

@mixin tablet-only {
  @media screen and (max-width: $breakpointLg + 'px') {
    @content;
  }
}

@mixin desktop-only {
  @media screen and (min-width: ($breakpointLg + 1) + 'px') {
    @content;
  }
}

@mixin wide-desktop-only {
  @media screen and (min-width: ($breakpointExtraLg + 1) + 'px') {
    @content;
  }
}

@mixin hide-on-desktop {
  @include desktop-only {
    display: none;
  }
}

@mixin hide-on-tablet {
  @include tablet-only {
    display: none;
  }
}

@mixin hide-on-mobile {
  @include mobile-only {
    display: none;
  }
}

@mixin font-size-xxl {
  font-size: map-get($fontSizes, xxl);
}

@mixin font-size-xl {
  font-size: map-get($fontSizes, xl);
}

@mixin font-size-l {
  font-size: map-get($fontSizes, l);
}

@mixin font-size-m {
  font-size: map-get($fontSizes, m);
}

@mixin font-size-s {
  font-size: map-get($fontSizes, s);
}

@mixin font-size-xs {
  font-size: map-get($fontSizes, xs);
}

@mixin font-light {
  font-weight: 300;
}

@mixin font-bold {
  font-weight: 600;
}

@mixin default-transition {
  transition: 0.2s ease;
}

@mixin rounded-corners {
  border-radius: $borderRadius;
}

@mixin size($size) {
  height: $size;
  width: $size;
}

@mixin text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
