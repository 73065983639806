@import 'src/styles/mixins';

.box {
  display: flex;
  background: $white;
  padding: 2.4rem 2rem;
  flex: 1 1 auto;
  flex-direction: column;
}

.boxHeader {
  @include font-size-xxl;

  color: $textColor;
}

.boxContent {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-top: $gapL;
}
